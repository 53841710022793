import React, { useState, useEffect } from 'react';
import HeroSection from './Hero/HeroSection';
import AboutSection from './About/AboutSection';
import ProjectsSection from './Projects/ProjectsSection';
import ContactSection from './Contact/ContactSection';

import { PortfolioProvider } from '../context/context';

import { heroData, aboutData, projectsData, contactData } from '../static/data';

function App() {
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [contact, setContact] = useState({});

  useEffect(() => {
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setContact({ ...contactData });
  }, []);

  return (
    <>
      <PortfolioProvider value={{ hero, about, projects, contact }}>
        <HeroSection />
        <AboutSection />
        <ProjectsSection />
        <ContactSection />
      </PortfolioProvider>
    </>
  );
}

export default App;
