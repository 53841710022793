import React, { useContext } from 'react';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';
import SectionTitle from '../SectionTitle/SectionTitle';

const AboutSection = () => {
  const { about } = useContext(PortfolioContext);
  const { title, image } = about;

  return (
    <section id="about" className="about-section">
      <SectionTitle title={title || 'About Me'} />
      <article className="about-me">
        <h2 className="about-me__title">Software Engineer</h2>
        <AboutImg filename={image} alt="Hieu" />
        <div className="about-me__main">
          <p>I&apos;m a curious developer who loves learning new things and building fun stuff with code.</p>
          <p>
            I didn&apos;t know I would choose software development as my career, but I always spent time messing around
            with the computer. I was a competitive chess player for more than 15 years. Software helped me a lot through
            the time: learning chess strategies, practicing with chess engines, preparing for opponents, etc.; I was
            taking advantage of software products. Around a few years ago, I “discovered” Linux and the world of FOSS.
            Since then, I&apos;ve appreciated all the effort people make for the software to be accessible and free
            (“Free as in Beer”) to use.
          </p>
          <p>I&apos;m now more than happy to contribute back to the community.</p>
        </div>
      </article>
    </section>
  );
};

export default AboutSection;
