import React, { useContext } from 'react';
import { ChevronsDown } from 'react-feather';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';
import HeroBackgroundImage from '../Image/HeroBackgroundImage';
import SectionTitle from '../SectionTitle/SectionTitle';

const HeroSection = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, cta } = hero;

  return (
    <HeroBackgroundImage alt={title}>
      <section id="hero" className="hero-section">
        <SectionTitle title={title || 'Introduce'} />
        <article className="intro">
          <h2 className="intro__title">Welcome to my Portfolio</h2>
          <p className="intro__main">
            <span>Hello</span>, My name is {name}.
          </p>
          {/* <p className="intro__sub">
            I&apos;m a <span>{role}</span>.
          </p> */}
          <button
            type="button"
            className="intro__action"
            aria-label="Scroll down to know more"
            title="Scroll down for more"
          >
            <Link to="about" smooth="easeInOutCubic" duration={1000}>
              {cta || 'Scroll Down'}
              <span className="intro__action-icon">
                <ChevronsDown color="#f3ca2f" />
              </span>
            </Link>
          </button>
        </article>
      </section>
    </HeroBackgroundImage>
  );
};

export default HeroSection;
