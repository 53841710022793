import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { ExternalLink } from 'react-feather';
import PortfolioContext from '../../context/context';
import ProjectImg from '../Image/ProjectImg';
import SectionTitle from '../SectionTitle/SectionTitle';
import ProjectsBackgroundImage from '../Image/ProjectsBackgroundImage';

const ProjectsSection = () => {
  const { projects } = useContext(PortfolioContext);

  const navigateToProject = (projectSlug) => {
    navigate(`/${projectSlug}`);
  };

  const openLink = (url) => {
    window.open(url, '_blank').focus();
  };

  return (
    <ProjectsBackgroundImage alt="My Personal Projects">
      <section id="projects" className="projects-section">
        <SectionTitle title="My Projects" />
        <section className="projects">
          <h2 className="projects__title">Fun Projects</h2>
          <div>
            {projects.map((project) => {
              const { title, info, img, id, slug, url } = project;

              return (
                <article
                  className="project"
                  role="presentation"
                  aria-hidden="true"
                  key={id}
                  onClick={() => (slug ? navigateToProject(slug) : openLink(url))}
                >
                  <ProjectImg alt={title} filename={img} key={id} />
                  <div>
                    {url && !slug && (
                      <div className="project__anchor">
                        <ExternalLink color="#fff" style={{ marginLeft: '0.25em' }} />
                      </div>
                    )}
                    <h3 className="project__title">{title}</h3>
                    <p className="project__description">{info}</p>
                  </div>
                </article>
              );
            })}
          </div>
        </section>
      </section>
    </ProjectsBackgroundImage>
  );
};

export default ProjectsSection;
