import React from 'react';
import PropTypes from 'prop-types';

const SectionTitle = ({ title }) => <aside className="section-title">{title}</aside>;

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SectionTitle;
