import React from 'react';
import App from '../components/App';
import { headData } from '../static/data';
import '../styles/main.scss';
import SEO from '../components/SEO/SEO';
import Layout from '../components/Layout/Layout';

const index = () => {
  const { title, lang, description } = headData;

  return (
    <>
      <SEO title={title} description={description} lang={lang} />
      <Layout>
        <App />
        {/* <ScrollToTop /> */}
      </Layout>
    </>
  );
};

export default index;
