import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const AboutImg = ({ filename, alt }) => {
  const { images } = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              fixed(quality: 80, width: 250) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const image = images.edges.find((n) => n.node.relativePath.includes(filename));
  const imageFixed = image ? image.node.childImageSharp.fixed : null;

  return imageFixed ? <Img className="about-me__image" alt={alt} fixed={imageFixed} /> : null;
};

AboutImg.propTypes = {
  filename: PropTypes.string,
  alt: PropTypes.string,
};

export default AboutImg;
