import React, { useContext } from 'react';
import PortfolioContext from '../../context/context';
import SectionTitle from '../SectionTitle/SectionTitle';

const ContactSection = () => {
  const { contact } = useContext(PortfolioContext);
  const { title, cta, btn, email } = contact;

  return (
    <section id="contact" className="contact-section">
      <SectionTitle title={title || 'Contact'} />
      <article className="contact-me">
        <h2 className="contact-me__title">Get In Touch</h2>
        <p className="contact-me__cta">{cta}</p>
        <a href={email ? `mailto:${email}` : `#`} aria-label="Send Email">
          <span className="contact-me__btn">{btn}</span>
        </a>
      </article>
    </section>
  );
};

export default ContactSection;
